import React, { PureComponent } from "react"


/* PureComponent Bet */
class Bet extends PureComponent {

    render = () => {

        const { onClick } = this.props

        return <div onClick={() => onClick()} className="game-button bet-button">BET</div>
    }

}

export default Bet