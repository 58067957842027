import React, { PureComponent } from "react"

/* Components */
import Picture from './Picture'

/* Constants */
import { utils, env } from "../constants"


/* Component Exchange */
class Exchange extends PureComponent {

    render = () => {

        const { onClick, isInsurance } = this.props

        if (isInsurance) {
            return (
                <div onClick={() => onClick()} className="game-button exchange-button mini-button">
                    <Picture src={`${env.mediapoint}/images/arrows.png`} />
                    <span>{utils.translate("EXCHANGE")}</span>
                </div>
            )
        }

        return <div onClick={() => onClick()} className="game-button exchange-button">{utils.translate("EXCHANGE")}</div>
    }

}

export default Exchange