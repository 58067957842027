import en from './en'
import ru from './ru'

const languages = [
    { code: "en", name: "English" },
    { code: "ru", name: "Русский" },
]

const data = { en, ru }

export { languages, data }