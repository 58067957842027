import React, { Component } from "react"

/* Constants */
import { env, utils } from '../constants'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Components */
import { Picture } from "../components"


/* Widget Transactions */
class Transactions extends Component {

    render = () => {

        const { info, operations, currency } = this.props

        if (info === null) {
            return <div />
        }

        return (
            <div className="game-transactions-box">

                <div className="game-transactions">
                    {operations && operations.map((item, index) => {

                        if (item.reason && !item.remove) {
                            return (
                                <div className="game-transaction" key={`transaction-${index}`}>

                                    <div className="title">{utils.translate(item.reason)}</div>

                                    {item.type === "credit"
                                        ? <span className="green">+ {utils.getWithCurrency(item.total, currency)}</span>
                                        : <span className="red">- {utils.getWithCurrency(item.total, currency)}</span>
                                    }

                                    {parseInt(item.status) === 0 &&
                                        <div className="game-transaction-loading">
                                            <LoadingOutlined style={{ fontSize: 18 }} />
                                        </div>
                                    }
                                    {parseInt(item.status) === 1 && <Picture src={`${env.mediapoint}/images/info/success.png`} alt="Success" />}
                                    {parseInt(item.status) === 2 && <Picture src={`${env.mediapoint}/images/info/warning.png`} alt="Fail" />}

                                </div>
                            )
                        }

                        return null
                    }
                    )}
                </div>

            </div>
        )
    }

}

export default Transactions