import React, { PureComponent } from "react"

/* Constants */
import { env, utils } from "../constants"

/* PureComponents */
import { Picture } from "../components"




/* Widget Preloader */
class Preloader extends PureComponent {

    render = () => {

        return (
            <div className="preloader">
                <Picture className="full-image" src={`${env.mediapoint}/images/preloader.webp`} alt="background" />
                <div className="preloader-logo">
                    <div className="preloader-logo-text">
                        <p>QUEEN OF</p>
                        <span>SPADES</span>
                    </div>
                </div>
                <div className="preloader-indicator">
                    <Picture className="line" src={`${env.mediapoint}/images/preloader/preloader.svg`} alt="Preloader" />
                    <Picture className="star" src={`${env.mediapoint}/images/preloader/star.svg`} alt="Preloader" />
                </div>
                <p className="support">{utils.translate("Supported by")}</p>
                <Picture className="makao-logo" src={`${env.mediapoint}/images/preloader/makao.svg`} alt="Preloader" />
            </div>
        )
    }

}

export default Preloader