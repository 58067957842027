import React, { PureComponent } from "react"

/* Constants */
import { utils, env } from '../constants'

/* Picture */
import { Picture } from "@alicorn/ep-ui"


/* Widget Result */
class PokerResult extends PureComponent {

    render = () => {

        const { result, currency, box } = this.props

        return (
            <div className={`poker-result ${result ? 'visible' : 'hidden'}`}>
                <div className="poker-result-box" />

                {result && (result.reason === "fold" || result.result === "lose") &&
                    <div className="poker-result-text lose">
                        <Picture src={`${env.mediapoint}/images/win/white-left.svg`} alt="Left" />
                        <div className="poker-result-inner-text">
                            <span>{utils.translate("DEALER WINS")}</span>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/white-right.svg`} alt="Right" />
                    </div>
                }

                {result && result.reason === "draw" &&
                    <div className="poker-result-text">
                        <Picture src={`${env.mediapoint}/images/win/white-left.svg`} alt="Left" />
                        <div className="poker-result-draw-text">
                            <span className="small">{utils.translate("DRAW")}</span>
                            <div className="poker-result-draw-price">{utils.getWithCurrency(result.sum, currency)}</div>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/white-right.svg`} alt="Right" />
                    </div>
                }

                {result && result.result === "win" && result.reason === "purchase-no-game" &&
                    <div className="poker-result-text">
                        <Picture src={`${env.mediapoint}/images/win/white-left.svg`} alt="Left" />
                        <div className="poker-result-draw-text">
                            <span className="small">{utils.translate("NO GAME")}</span>
                            <div className="poker-result-draw-price">{utils.getWithCurrency(result.sum, currency)}</div>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/white-right.svg`} alt="Right" />
                    </div>
                }

                {result && result.result === "win" && (result.reason === "nogame" || result.reason === "bet") &&
                    <div className="poker-result-text">
                        <Picture src={`${env.mediapoint}/images/win/money-left.svg`} alt="Left" />
                        <div className="poker-result-win-text">
                            <span className="small">{utils.translate("YOU WIN")}</span>
                            <div className="poker-result-win-price">{utils.getWithCurrency(result.sum, currency)}</div>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/money-right.svg`} alt="Right" />
                    </div>
                }

            </div>
        )
    }

}

export default PokerResult