import React, { PureComponent } from "react"

/* Icons */
import { CaretDownOutlined } from '@ant-design/icons'

/* Constants */
import { env, utils } from "../constants"

/* PureComponents */
import { Picture } from "../components"

/* Moment */
import moment from "moment"
import 'moment/locale/ru'
moment.locale('ru')


const types = {
    "exchange": <Picture src={`${env.mediapoint}/images/arrows.png`} alt="Arrow" />,
    "sixth": "+1",
    "removed": <Picture src={`${env.mediapoint}/images/close.png`} alt="X" />,
    "purchase": "+1"
}

/* PureComponent History */
class History extends PureComponent {

    constructor() {
        super()

        this.state = {
            active: -1
        }

        this._history = null
    }

    tr = (text) => {
        return utils.translate(text)
    }

    withCr = (value, currency) => {
        return utils.getWithCurrency(value, currency)
    }

    /* Determine and draw total */
    _total = data => {

        let total = 0
        let currency = "USD"

        if (data.transactions && Array.isArray(data.transactions) && data.transactions.length > 0) {

            data.transactions.forEach(transaction => {

                if (transaction.type === 'credit') {
                    total = total + parseFloat(transaction.total)
                }

                if (transaction.type === 'debit') {
                    total = total - parseFloat(transaction.total)
                }

                currency = transaction.currency

            })

        }

        if (total > 0) {
            return <div className="e-history-head-total">+ {utils.getWithCurrency(Math.abs(total), currency)}</div>
        }

        if (total < 0) {
            return <div className="e-history-head-total">- {utils.getWithCurrency(Math.abs(total), currency)}</div>
        }

        return <div className="e-history-head-total">{utils.getWithCurrency(total, currency)}</div>
    }


    /* Draw time */
    _time = data => {

        if (data.createdAt) {

            const date = moment(data.createdAt).format("YYYY-MM-DD")
            const today = moment(new Date()).format("YYYY-MM-DD")

            const yesterdayD = new Date()
            yesterdayD.setDate(yesterdayD.getDate() - 1)
            const yesterday = moment(yesterdayD).format("YYYY-MM-DD")

            let text = date

            if (moment(date).isSame(today, 'day')) {
                text = utils.translate('Today')
            }
            else if (moment(date).isSame(yesterday, 'day')) {
                text = utils.translate('Yesterday')
            }

            return (
                <div className="e-history-head-datetime">
                    <div className="e-history-head-time">{moment(data.createdAt).format("HH:mm")}</div>&nbsp;
                    <div className="e-history-head-date">{text}</div>
                </div>
            )
        }
    }

    /* Return card type text */
    _cardType = (type) => {
        if (types[type]) {
            return types[type]
        }
        return ""
    }


    /* Draw cards */
    _cards = data => {

        let dealer = []
        let box1 = []
        let box2 = []

        let box1Game = null
        let box2Game = null
        let dealerGame = null

        /* GAME RESULTS */
        if (data.results && Array.isArray(data.results) && data.results.length > 0) {
            const pindex = data.results.findIndex(e => e.type === "player" && parseInt(e.box) === 1)
            if (pindex > -1) {
                box1Game = {
                    name: data.results[pindex].result,
                    multiplier: data.results[pindex].multiplier
                }
            }
            const p2index = data.results.findIndex(e => e.type === "player" && parseInt(e.box) === 2)
            if (p2index > -1) {
                box2Game = {
                    name: data.results[p2index].result,
                    multiplier: data.results[p2index].multiplier
                }
            }
        }

        if (data.results && Array.isArray(data.results) && data.results.length > 0 && data.results[1]) {
            const dindex = data.results.findIndex(e => e.type === "dealer")
            if (dindex > -1) {
                dealerGame = {
                    name: data.results[dindex].result,
                    multiplier: data.results[dindex].multiplier
                }
            }
        }

        /* Cards */
        if (Array.isArray(data.cards) && data.cards.length > 0) {
            data.cards.forEach(card => {
                if (card.type === "dealer") {
                    dealer.push(card)
                }
                if (card.type === "player" && parseInt(card.box) === 1) {
                    box1.push(card)
                }
                if (card.type === "player" && parseInt(card.box) === 2) {
                    box2.push(card)
                }
            })
        }

        return (
            <div className="e-history-cards-box">

                <div className="e-history-cards-box-item">
                    {dealer.length > 0 ? <div className="e-history-cards-box-text">{utils.translate('Dealer')}</div> : null}
                    {(dealerGame && dealerGame.name) ? <p>{utils.translate(dealerGame.name)}{` : (${dealerGame.multiplier} : 1)`}</p> : null}
                    <div className="e-history-cards">
                        {dealer.map((card, index) =>
                            <div key={`${index}`} className="e-history-card-item" style={{ opacity: card.status === "removed" ? 0.5 : 1 }}>
                                <Picture src={`${env.mediapoint}/cards/${card.image}`} alt="Card" />
                                <span>{this._cardType(card.status)}</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="e-history-cards-box-item">
                    {box1.length > 0 ? <div className="e-history-cards-box-text">{utils.translate('BOX 1')}</div> : null}
                    {box1Game !== null ? <p>{utils.translate(box1Game.name)}{` : (${box1Game.multiplier} : 1)`}</p> : null}
                    <div className="e-history-cards">
                        {box1.map((card, index) =>
                            <div key={`${index}`} className="e-history-card-item" style={{ opacity: card.status === "hidden" ? 0.5 : 1 }}>
                                <Picture src={`${env.mediapoint}/cards/${card.image}`} alt="Card" />
                                <span>{this._cardType(card.status)}</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="e-history-cards-box-item">
                    {box2.length > 0 ? <div className="e-history-cards-box-text">{utils.translate('BOX 2')}</div> : null}
                    {box2Game !== null ? <p>{utils.translate(box2Game.name)}{` : (${box2Game.multiplier} : 1)`}</p> : null}
                    <div className="e-history-cards">
                        {box2.map((card, index) =>
                            <div key={`${index}`} className="e-history-card-item" style={{ opacity: card.status === "hidden" ? 0.5 : 1 }}>
                                <Picture src={`${env.mediapoint}/cards/${card.image}`} alt="Card" />
                                <span>{this._cardType(card.status)}</span>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        )

    }


    /* Draw current game */
    // _currentGame = () => {

    //     /* Props and state */
    //     const { historyData } = this.props
    //     const { info, transactions } = historyData

    //     const { active } = this.state

    //     if (info && transactions && Array.isArray(transactions) && transactions.length > 0) {
    //         return (
    //             <div className="history-item">

    //                 {/* Draw history head */}
    //                 <div onClick={() => this.setState({ active: active === 'current' ? -1 : 'current' })} className={`e-history-head ${'current' === active ? 'e-history-active-head' : ''}`}>

    //                     <div className="e-history-number">
    //                         Игра #{info.number}
    //                         <div className="e-history-number-text"> (ТЕКУЩАЯ ИГРА)</div>
    //                     </div>

    //                     <div className="e-history-head-right">
    //                         <CaretDownOutlined />
    //                     </div>

    //                 </div>

    //                 <div className={`history-hidden-box ${'current' === active ? 'history-hidden-box-active' : ''}`}>

    //                     {(transactions && Array.isArray(transactions) && transactions.length > 0) &&
    //                         <div className="history-transactions">
    //                             <table className="operations-table">
    //                                 <thead>
    //                                     <tr>
    //                                         <th>Type</th>
    //                                         <th>Amount</th>
    //                                     </tr>
    //                                 </thead>

    //                                 <tbody>
    //                                     {transactions.map((transaction, index) =>
    //                                         <tr key={`h-o-${index}`} className="item">
    //                                             <td>{transaction.reason}</td>
    //                                             <td>{utils.convertor(transaction.total, transaction.currency)}</td>
    //                                         </tr>
    //                                     )}
    //                                 </tbody>
    //                             </table>
    //                         </div>
    //                     }
    //                 </div>
    //             </div>
    //         )
    //     }

    //     return null
    // }

    /* Operations list */
    operationsList = item => {

        if (item.processes && Array.isArray(item.processes) && item.processes.length > 0) {

            let total = 0
            let totalWin = 0

            let currency = "USD"

            let totalWinTransactions = 0

            if (item.transactions && Array.isArray(item.transactions) && item.transactions.length > 0) {
                currency = item.transactions[0].currency
                item.transactions.forEach(transaction => {
                    if (transaction.type === 'credit') {
                        totalWinTransactions += parseFloat(transaction.total)
                    }
                })
            }

            let boxes = []

            item.processes.forEach(item => {

                if (item.total) {
                    if (item.type !== "maxpay") {
                        total += parseFloat(item.total)
                    }
                }

                if (item.win) {
                    totalWin += parseFloat(item.win)
                }

                const i = boxes.findIndex(e => parseInt(e) === parseInt(item.box))
                if (i === -1) {
                    boxes.push(parseInt(item.box))
                }
            })

            let gameProcesses = [[], []]
            let array = item.processes
            boxes.forEach((box, index) => {
                gameProcesses[index] = array.filter((e) => parseInt(e.box) === parseInt(box))
            })

            return (
                <div className="history-operations">
                    <table className="operations-table">
                        <thead>
                            <tr>
                                <th className="table-first"></th>
                                <th>{this.tr("Type")}</th>
                                <th>{this.tr("Amount")}</th>
                                <th>{this.tr("Paid")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                gameProcesses.map((array, ind) => {
                                    return array.map((item, index) => {
                                        const processStyle = item.type === 'maxpay' ? 'maxpay' : item.type === 'pass' ? 'pass' : ''
                                        let text = this.tr(item.reason)

                                        if (item.type === 'maxpay') {
                                            return (
                                                <tr key={`h-o-${index}`} className={`item ${processStyle}`}>
                                                    <td colSpan={3}>{`${this.tr("YOU HAVE BEEN PAID THE MAXIMUM AMOUNT")} - ${this.withCr(item.total, currency)}`}</td>
                                                </tr>
                                            )
                                        }

                                        return (
                                            <tr key={`h-o-${index}`} className={`item ${processStyle}`}>
                                                {index === 0 ? <td className="table-first" rowSpan={array.length}>{`${this.tr("HAND")} - ${ind + 1}`}</td> : null}
                                                <td>{text}</td>
                                                <td>{this.withCr(item.total, currency)}</td>
                                                <td className="value">{this.withCr(item.win ? item.win : 0, currency)}</td>
                                            </tr>
                                        )
                                    })

                                })
                            }

                            <tr key={`h-o-total`}>
                                <td className="table-first"></td>
                                <td>{this.tr('Total')}</td>
                                <td className="total">{this.withCr(Math.abs(total), currency)}</td>
                                <td className="total">{this.withCr(Math.abs(totalWinTransactions), currency)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            )
        }

        return null
    }


    /* Draw Refund */
    _refund = item => {

        if (item.transactions && Array.isArray(item.transactions) && item.transactions.length > 0) {
            const result = item.transactions.filter(e => e.reason === "Replenishment of the amount" || e.reason === "Return of funds")
            if (result && Array.isArray(result) && result.length > 0) {
                return (
                    <div className="e-history-refund-transactions">
                        <div className="e-history-refund-transaction-title">{utils.translate("Refunds")}</div>
                        {result.map((transaction, i) =>
                            <div className="e-history-refund-transaction" key={`${i}`}>
                                <div className="e-history-refund-transaction-name">{utils.translate(transaction.reason)}</div>
                                <div className="e-history-refund-transaction-value">{utils.getWithCurrency(transaction.total, transaction.currency)}</div>
                            </div>
                        )}
                    </div>
                )
            }
        }
    }

    render = () => {

        /* Props and state */
        const { historyData, loadMore, mobile, close } = this.props
        const { active } = this.state

        const { histories, historyHasMore } = historyData

        /* Fields */
        const bottomStyle = histories.length > 10 ? 'initial' : ''

        return (
            <div className='e-history-content'>

                {/* Modal Header */}
                {!mobile &&
                    <div className='e-history-header'>
                        {utils.translate("History")}
                        <div onClick={() => close()} className="e-history-close">
                            <Picture src={`${env.mediapoint}/images/close.png`} alt="CLOSE" />
                        </div>
                    </div>
                }

                {/* Modal Body */}
                <div className='e-history-body' ref={ref => this._history = ref}>

                    {/* The current game */}
                    {/* {this._currentGame()} */}

                    {/* Old games */}
                    {histories.map((item, index) => {

                        let endBalance = parseFloat(item.startBalance)

                        if (item.transactions && Array.isArray(item.transactions) && item.transactions.length > 0) {
                            item.transactions.forEach(transaction => {
                                if (transaction.type === 'credit') {
                                    endBalance = endBalance + parseFloat(transaction.total)
                                }
                                if (transaction.type === 'debit') {
                                    endBalance = endBalance - parseFloat(transaction.total)
                                }
                            })
                        }

                        return (
                            <div className={`e-history-item ${index === active ? 'visible' : 'default'}`} key={`${index}`}>

                                {/* Draw history head */}
                                <div onClick={() => this.setState({ active: active === index ? -1 : index })} className={`e-history-head ${index === active ? 'e-history-active-head' : ''}`}>

                                    <div className="e-history-number">
                                        {utils.translate("Game")} #{item.number}
                                        {/* {index === 0 && <div className="history-number-text"> (LAST GAME)</div>} */}
                                    </div>

                                    <div className="e-history-head-right">
                                        {this._total(item)}
                                        {this._time(item)}
                                        <CaretDownOutlined />
                                    </div>

                                </div>

                                <div className={`e-history-hidden-box ${index === active ? 'e-history-hidden-box-active' : ''}`}>

                                    {item.endReason ? <div className="e-history-reason-error">{utils.translate(item.endReason)}</div> : null}

                                    {(item.transactions && Array.isArray(item.transactions) && item.transactions.length > 0) &&
                                        <div className="history-operations">

                                            {(item.startBalance !== null || item.startBalance !== undefined) && <div className="e-history-transaction-balance">{utils.translate("Balance before the game:")} {utils.getWithCurrency(item.startBalance, item.transactions[0].currency)}</div>}
                                            {endBalance > 0 && <div className="e-history-transaction-balance">{utils.translate("Balance after the game:")} {utils.getWithCurrency(endBalance, item.transactions[0].currency)}</div>}

                                            {this._refund(item)}
                                            {this.operationsList(item, index)}

                                        </div>
                                    }

                                    {this._cards(item)}

                                </div>
                            </div>
                        )
                    })}

                    {historyHasMore &&
                        <div className={`e-history-bottom ${bottomStyle}`}>
                            <div className="e-history-load-more" onClick={() => loadMore()}>{utils.translate("Load more")}</div>
                        </div>
                    }

                    {histories.length > 4 ? <div className="history-bottom-space" /> : null}

                </div>
            </div>

        )

    }

}

export default History