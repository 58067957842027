import React, { PureComponent } from "react"

/* Constants */
import { utils, env } from '../constants'

/* Picture */
import { Picture } from "../components"


/* Widget Result */
class Result extends PureComponent {

    render = () => {

        const { result, currency, box } = this.props

        return (
            <div className={`result ${result ? 'visible' : 'hidden'}`}>
                <div className="result-box" />

                {result && (result.reason === "fold" || result.result === "lose") &&
                    <div className="result-text">
                        <Picture src={`${env.mediapoint}/images/win/white-left.svg`} alt="Left" />
                        <div className="result-inner-text">
                            <span>{utils.translate("BOX")} {box + 1}</span>
                            <span>{utils.translate("DEALER WINS")}</span>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/white-right.svg`} alt="Right" />
                    </div>
                }

                {result && result.reason === "draw" &&
                    <div className="result-text">
                        <Picture src={`${env.mediapoint}/images/win/white-left.svg`} alt="Left" />
                        <div className="result-draw-text">
                            <span>{utils.translate("BOX")} {box + 1}</span>
                            <span>{utils.translate("DRAW")}</span>
                            <div className="result-draw-price">{utils.getWithCurrency(result.sum, currency)}</div>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/white-right.svg`} alt="Right" />
                    </div>
                }

                {result && result.result === "win" && result.reason === "purchase-no-game" &&
                    <div className="result-text">
                        <Picture src={`${env.mediapoint}/images/win/white-left.svg`} alt="Left" />
                        <div className="result-draw-text">
                            <span>{utils.translate("BOX")} {box + 1}</span>
                            <span>{utils.translate("NO GAME")}</span>
                            <div className="result-draw-price">{utils.getWithCurrency(result.sum, currency)}</div>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/white-right.svg`} alt="Right" />
                    </div>
                }

                {result && result.result === "win" && (result.reason === "nogame" || result.reason === "bet") &&
                    <div className="result-text">
                        <Picture src={`${env.mediapoint}/images/win/money-left.svg`} alt="Left" />
                        <div className="result-win-text">
                            <span>{utils.translate("BOX")} {box + 1}</span>
                            <span>{utils.translate("YOU WIN")}</span>
                            <div className="result-win-price">{utils.getWithCurrency(result.sum, currency)}</div>
                        </div>
                        <Picture src={`${env.mediapoint}/images/win/money-right.svg`} alt="Right" />
                    </div>
                }

                {(result && result.bonus && result.bonus.win && result.bonus.win > 0)
                    ? <div className="result-bonus-text">
                        BONUS: <span>{utils.getWithCurrency(result.bonus.win, currency)}</span>
                    </div>
                    : null
                }

            </div>
        )
    }

}

export default Result