import React, { PureComponent } from "react"

/* Constants */
import { env, utils } from "../constants"

/* PureComponents */
import Picture from "./Picture"


/* Widget Error */
class Error extends PureComponent {

    reload = () => {
        window.location.reload(false)
    }

    render = () => {

        const { closed, newDevice, isDisconnected } = this.props

        if (closed) {
            return (
                <div className="error">
                    <Picture src={`${env.mediapoint}/images/error/time-left.png`} alt="Error" />
                    <div className="error-text">{utils.translate("Your session has expired due to inactivity!")}</div>
                    <div className="error-description">{utils.translate("To start the game again, please refresh the page.")}</div>
                    <div className="error-button reload-button" onClick={() => this.reload()}>{utils.translate("Reload")}</div>
                </div>
            )
        }

        if (isDisconnected && !newDevice) {
            return (
                <div className="error">
                    <Picture src={`${env.mediapoint}/images/error/time-left.png`} alt="Error" />
                    <div className="error-text">{utils.translate("You are logged out of the game!")}</div>
                    <div className="error-description">{utils.translate("To continue, please refresh the page.")}</div>
                    <div className="error-button reload-button" onClick={() => this.reload()}>{utils.translate("Reload")}</div>
                </div>
            )
        }

        if (newDevice) {
            return (
                <div className="error">
                    <Picture src={`${env.mediapoint}/images/error/tab.png`} alt="Error" />
                    <div className="error-text">{utils.translate("You have entered the game from a new tab.")}</div>
                    <div className="error-description">{utils.translate("Something went wrong! Try again later or refresh the page.")}</div>
                    <div className="error-button reload-button" onClick={() => this.reload()}>{utils.translate("Reload")}</div>
                </div>
            )
        }

        return (
            <div className="error">
                <Picture src={`${env.mediapoint}/images/error/error.png`} alt="Error" />
                <div className="error-text">{utils.translate("Error!")}</div>
                <div className="error-description">{utils.translate("Something went wrong! Try again later or refresh the page.")}</div>
                <div className="error-button" onClick={() => this.reload()}>{utils.translate("Reload")}</div>
            </div>
        )
    }

}

export default Error