import React, { PureComponent } from "react"

/* Constants */
import { env, utils } from "../constants"

/* Components */
import Picture from "./Picture"

/* Component Insurance */
class Insurance extends PureComponent {

    render = () => {

        const { onClick } = this.props

        return (
            <div onClick={() => onClick()} className="game-button insurance-button">
                <span>{utils.translate("INSURANCE")}</span>
                <Picture src={`${env.mediapoint}/images/insurance.png`} />
            </div>
        )
    }

}

export default Insurance